<template>
    <span class="font-bold"> 
        <span class="text-red-500" v-if="item.real_ketinggian >= item.siaga_1">
            {{parseFloat(item.real_ketinggian * 100).toFixed(2)}} cm
        </span>
        <span class="text-yellow-500" v-else-if="item.real_ketinggian <= item.siaga_1 && item.real_ketinggian >= item.siaga_2">
            {{parseFloat(item.real_ketinggian * 100).toFixed(2)}} cm
       </span>
        <span class="text-blue-500" v-else-if="item.real_ketinggian <= item.siaga_2 && item.real_ketinggian >= item.siaga_3">
            {{parseFloat(item.real_ketinggian * 100).toFixed(2)}} cm
        </span>
        <span class="text-green-500" v-else>
            {{parseFloat(item.real_ketinggian * 100).toFixed(2)}} cm
        </span>
    </span>
</template>

<script>
export default {
    props:['item'],
}
</script>
