<template>
    <span class="font-bold"> 
         <span style="color:#C2410C !important" v-if="item.pH >= 1 && item.pH < 2" >
            {{parseFloat(item.pH).toFixed(2)}} 
            
            Veri Acidic
        </span>
        <span style="color:#EA580C !important" v-else-if="item.pH >= 2 && item.pH < 3">
            {{parseFloat(item.pH).toFixed(2)}} 
            
            
            Veri Acidic

        </span>
        <span style="color:#F97316 !important" v-else-if="item.pH >= 3 && item.pH < 4">
            {{parseFloat(item.pH).toFixed(2)}} 
            
            Veri Acidic

        </span>
        <span style="color:#EAB308 !important"  v-else-if="item.pH >= 4 && item.pH < 5">
            {{parseFloat(item.pH).toFixed(2)}} 
            
            Acidic
        </span>
        <span style="color:#FACC15 !important"  v-else-if="item.pH >= 5 && item.pH < 6">
            {{parseFloat(item.pH).toFixed(2)}} 
            
            Slightly Acidic
        </span>
        <span style="color:#A3E635 !important"   v-else-if="item.pH >= 6 && item.pH < 7">
            {{parseFloat(item.pH).toFixed(2)}} 
            
            Slightly Acidic
        </span>
        <span style="color:#84CC16 !important"   v-else-if="item.pH >= 7 && item.pH < 8">
            {{parseFloat(item.pH).toFixed(2)}} 
            
            Neutral
        </span>
        <span style="color:#10B981 !important" v-else-if="item.pH >= 8 && item.pH < 9">
            {{parseFloat(item.pH).toFixed(2)}}
            
            
            Slightly Alkaline
           
        </span>
        <span style="color:#059669 !important" v-else-if="item.pH >= 9 && item.pH < 10">
            {{parseFloat(item.pH).toFixed(2)}} 
            Slightly Alkaline
           
        </span>
        <span style="color:#047857 !important" v-else-if="item.pH >= 10 && item.pH < 11">
            {{parseFloat(item.pH).toFixed(2)}} 
            

            Alkaline
        </span>
        <span style="color:#065F46 !important"  v-else-if="item.pH >= 11 && item.pH < 12">
            {{parseFloat(item.pH).toFixed(2)}} 
            

            Very Alkaline
        </span>
            <span style="color:#134E4A !important"  v-else-if="item.pH >= 12 && item.pH < 13">
            {{parseFloat(item.pH).toFixed(2)}} 
            

            Very Alkaline

        </span>
        <span style="color:#1E3A8A !important" v-else-if="item.pH >= 13 && item.pH < 14">
            {{parseFloat(item.pH).toFixed(2)}}
            
            
            Very Alkaline
           
        </span>
        <span style="color:#312E81 !important"   v-else-if="item.pH >= 14">
            {{parseFloat(item.pH).toFixed(2)}} 
            

            Very Alkaline
    
        </span>
    </span>
</template>

<script>
import globalMixin from '../../mixin/global'
export default {
    props:['item'],
    mixins:[globalMixin]
}
</script>
